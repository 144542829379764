@use './styles/_mixins.scss' as *;
.filters {
  @include breakpoint(large) {
    max-width: 320px;
  }

  &_plain {
    @include breakpoint(large) {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 70px;
      position: relative;
      z-index: 3;
      .checkbox_span {
        transition: color 0.3s ease;
        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  &_top {
    display: flex;
    justify-content: center;
    align-items: center;

    .filters_clear {
      margin-left: 32px;
    }

    @include breakpoint(large) {
      justify-content: space-between;
      align-items: center;
      padding-bottom: rem(30);
      margin-bottom: rem(30);
      border-bottom: 1px solid var(--color-gray);

      .filters_plain & {
        background: var(--color-white);
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        margin-right: -15px;
      }

      .filters_clear {
        margin-left: 0;
      }
    }

    &_title {
      display: flex;
      align-items: center;
      font-size: rem(15);
      font-weight: var(--font-medium);

      @include breakpoint(large) {
        font-size: rem(18);
      }
      .filters_plain & {
        @include breakpoint(untilLarge) {
          svg path {
            fill: var(--color-dark);
          }
        }
        @include breakpoint(large) {
          display: none;
        }
      }
    }

    svg {
      margin-left: rem(10);
    }
  }

  &_clear {
    font-size: rem(14);
    text-decoration: underline;
    cursor: pointer;
    flex-shrink: 0;
    @include breakpoint(large) {
      font-weight: var(--font-regular);
      .filters_plain & {
        position: relative;
        padding: 0 rem(32) 0 rem(16);
        font-weight: var(--font-medium);

        &:after {
          position: absolute;
          content: '';
          right: 13px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 64px;
          background: linear-gradient(
            180deg,
            rgba(190, 187, 190, 0) 0%,
            #bebbbe 50.5%,
            rgba(190, 187, 190, 0) 100%
          );
        }
      }
    }
  }

  &_wrap {
    @include breakpoint(untilLarge) {
      position: fixed;
      padding: rem(70) rem(8) rem(16) rem(8);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      color: var(--color-white);
      visibility: hidden;
      opacity: 0.2;
      transition:
        opacity 0.1s ease-in,
        visibility 0.1s ease-in;
      overflow: auto;
      z-index: 3;

      &_open {
        visibility: visible;
        opacity: 1;

        .filters_wrap_inner {
          visibility: visible;
          max-width: 600px;
          margin: auto;
          opacity: 1;
          transform: scale(1);
          max-height: 87vh;
          overflow: hidden;
          transition:
            transform 0.3s ease-out,
            opacity 0.3s ease-out,
            visibility 0.3s ease-out;
        }
      }

      &_inner {
        padding: rem(24) rem(12) rem(8) rem(12);
        background: var(--color-dark);
        border-radius: 16px;
        visibility: hidden;
        opacity: 0.2;
        transform: scale(0.8);
        transform-origin: center;
        transition:
          transform 0.3s ease-in,
          opacity 0.1s ease-in,
          visibility 0.1s ease-in;
      }
      &_close {
        font-size: rem(20);
        flex: 1;
      }

      &_top {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(18);
        padding: 0 rem(8) rem(24) rem(8);

        &_title {
          display: flex;
          align-items: center;
          gap: 16px;
          width: 100%;
          &:after {
            flex: 1;
            content: '';
          }
        }
      }
    }
    @include breakpoint(large) {
      .filters_plain & {
        height: 100%;
        border-radius: 16px;
        background: var(--color-white);
      }
    }
    &_inner {
      @include breakpoint(large) {
        .filters_plain & {
          display: flex;
          height: 100%;
        }
      }
    }
  }

  &_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(24);
    border-radius: 99px;
    background: rgba(241, 243, 244, 0.24);
    font-size: rem(14);

    @supports (-webkit-touch-callout: none) {
      @include breakpoint(small) {
        font-size: rem(16);
      }
    }

    @include breakpoint(large) {
      font-size: rem(15);

      .filters_plain & {
        padding: 0 rem(24);
        margin-bottom: 0;
        background: unset;
        border: none;
        font-size: rem(15);
        font-weight: var(--font-medium);
        order: 3;
        input {
          max-width: 140px;
          height: 100%;
          padding: 0;
          padding-right: rem(16);
          color: var(--color-dark);
          &::placeholder {
            color: var(--color-dark);
          }
        }
        svg {
          fill: var(--color-dark);
        }
      }
    }

    @media (min-width: 1280px) and (max-width: 1380px) {
      .filters_plain & {
        padding: 0 rem(16);
        font-size: rem(13);
      }
    }

    input {
      flex: 1;
      padding: rem(16) rem(20);
      background: transparent;
      border: 0;
      font-size: rem(14);
      border-radius: 0;
      outline: none;
      color: var(--color-white);

      @supports (-webkit-touch-callout: none) {
        @include breakpoint(small) {
          font-size: rem(16);
        }
      }
      &::placeholder {
        color: var(--color-grayDark);
      }
    }

    &_icon {
      position: relative;
      right: 20px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &_top {
    &_title {
      svg {
        @include breakpoint(large) {
          display: none;
        }
      }
    }
  }
  &_wrap {
    @include breakpoint(large) {
      &_top {
        display: none;
      }
    }
    &_close {
      @include breakpoint(large) {
        display: none;
      }
    }
  }

  &_item {
    display: flex;
    flex-flow: column;
    font-size: rem(15);
    @include breakpoint(untilLarge) {
      border-top: 1px solid rgba(255, 255, 255, 0.12);

      &:last-child {
        border-bottom: none;
      }

      &_active {
        .filters_item_wrap {
          max-height: 1000px;
        }
        .filters_item_chevron {
          transform: rotate(-90deg);
        }
      }
    }

    &_active {
      .filters_plain & {
        .filters_item_wrap {
          max-height: 47vh;
        }
        .filters_item_chevron {
          transform: rotate(-90deg);
        }
      }
    }

    @include breakpoint(large) {
      padding-bottom: rem(30);

      .filters_plain & {
        position: relative;
        min-width: 180px;
        padding: 0 rem(24);
        justify-content: center;
        cursor: pointer;

        &:nth-child(3) {
          min-width: 204px;
        }

        &:after {
          position: absolute;
          content: '';
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 64px;
          background: linear-gradient(
            180deg,
            rgba(190, 187, 190, 0) 0%,
            #bebbbe 50.5%,
            rgba(190, 187, 190, 0) 100%
          );
        }
      }
    }

    @media (min-width: 1280px) and (max-width: 1380px) {
      .filters_plain & {
        padding: 0 rem(16);
      }
    }

    &_title {
      font-size: rem(16);
      font-weight: var(--font-regular);
      text-transform: capitalize;

      @include breakpoint(untilLarge) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(16) 0;
      }

      @include breakpoint(large) {
        margin-bottom: rem(20);

        .filters_plain & {
          margin-bottom: 0;
          display: flex;
          gap: 4px;
          font-size: rem(13);
          color: var(--color-gray3);
          font-weight: var(--font-medium);
          justify-content: space-between;
          align-items: center;
          // max-width: 120px;
        }
      }
    }

    &_choice {
      display: none;
      .filters_plain & {
        @include breakpoint(large) {
          display: block;
          margin-top: rem(10);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: rem(13);
          font-weight: var(--font-medium);
        }
        @include breakpoint(huge) {
          font-size: rem(14);
        }
      }
    }

    &_chevron {
      transform: rotate(90deg);
      transition: transform 0.1s ease;
      @include breakpoint(large) {
        display: none;
        .filters_plain & {
          display: block;
          path {
            fill: var(--color-gray3);
          }
        }
      }
    }

    &_wrap {
      .filters_plain & {
        @include breakpoint(large) {
          position: absolute;
          top: 45px;
          left: 0;
          right: 1px;
          background: var(--color-white);
          border-top: 0;
          overflow: hidden;
          max-height: 0px;
          padding: 0 rem(16);
          transition: max-height 0.3s ease;
          z-index: 1;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          overflow-y: auto;
          box-shadow:
            0 0 0 0px rgba(72, 72, 75, 0.05),
            0 15px 11px -4px rgba(72, 72, 75, 0.25);

          &::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            margin-bottom: 10px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: var(--color-primary);
          }

          .checkbox {
            &_span {
              color: var(--color-secondary);
              font-weight: var(--font-medium);

              &:hover {
                color: var(--color-primary);
              }
              &:before {
                display: none;
              }
            }
            &_link:first-child {
              padding-top: rem(20);
            }
          }
        }
      }

      @include breakpoint(untilLarge) {
        // overflow: hidden;
        overflow: auto;
        max-height: 0px;
        transition: max-height 0.3s ease;

        .checkbox_link:first-child {
          padding-top: rem(8);
        }
      }
    }
  }
}

.checkbox_link {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: rem(14);
  cursor: pointer;
  overflow: hidden;

  &_disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.3;
  }
}
.checkbox_span {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: var(--color-grayDark);
  font-size: rem(14);

  @include breakpoint(large) {
    font-size: rem(15);
    // align-items: flex-end;
    .filters_plain & {
      font-size: rem(13);
    }
  }

  &:before {
    content: '';
    width: rem(20);
    height: rem(20);
    border-radius: 3px;
    transition: background 0.1s ease-in-out;
    border: 1px solid rgba(241, 243, 244, 0.24);
    background: rgba(241, 243, 244, 0.24);
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
    @include breakpoint(large) {
      width: rem(22);
      height: rem(22);
    }
  }
  &:after {
    position: absolute;
    content: '\2713';
    left: 5px;
    top: 1px;
    font-weight: var(--font-demi);
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    @include breakpoint(large) {
      left: 5px;
      top: 3px;
    }

    @include breakpoint(large) {
      .filters_plain & {
        display: none;
      }
    }
  }
}

.selected_filter {
  @include breakpoint(large) {
    .checkbox_span {
      .filters_plain & {
        color: var(--color-primary);
      }
    }
  }
  .checkbox_span:before {
    background: var(--color-primary);
  }
  .checkbox_span:after {
    opacity: 1;
  }
}
